import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './ProjectList.module.css'
import ProjectTile from "../../tile/ProjectTile/ProjectTile"

export default function ProjectList() {
    const data = useStaticQuery(graphql`
    query allProjects {
        mdx(slug: {eq: "projects"}) {
            frontmatter {
              projects {
                name,
                link,
                image {
                    childImageSharp {
                        gatsbyImageData(
                            width: 170
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                },
                key
              }
            }
          }
      }
    `);

    const projects = data.mdx.frontmatter.projects;


    return (
        <div className={styles.projectList}>
            {   
                projects.map((data)=>{
                    return <ProjectTile 
                        name={data.name} 
                        imageData={data.image.childImageSharp}
                        key={data.key}
                        link={data.link}
                    />
                })
            }
        </div>
    )
}