import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as styles from './ProjectTile.module.css'
import Link from '../../../utils/Link/Link';


export default function ProjectTile({name, imageData, link=""}) {

    const image = getImage(imageData);

    return (
        <div className={styles.tile}>
            <Link to={link}>
                <GatsbyImage image={image} alt="" className={styles.project_img} imgClassName={styles.img}/>
            </Link>
            <p className={styles.text}>{name}</p>
            <Link to={link} target="_blank"  className={styles.text_button}>Details</Link>
        </div>
    )
}
